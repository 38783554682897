import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import TeaserPost from "../components/TeaserPost"
import FeaturedPosts from "../components/FeaturedPosts"
import LatestPosts from "../components/LatestPosts"

// const filterDevPosts = edge =>
//   process.env.NODE_ENV === "development" ||
//   !edge.node.frontmatter.developmentpost

const IndexPage = ({ data }) => (
  <>
    <SEO title="Home" />
    <TeaserPost
      slug="purl-through-back-loop"
      headline="Purl Through The Back Loop: The Technique To Learn"
    />
    <FeaturedPosts
      slugs={[
        {
          slug: "make-one-left",
          headline: "You Need To Make One Left: Here's How",
          kicker: "Tutorial",
        },
        { slug: "knit-two-together" },
        { slug: "knit-stitch-k" },
        { slug: "managing-tension" },
      ]}
    />
    <LatestPosts posts={data.allMarkdownRemark.edges} />
  </>
)

export default IndexPage

export const query = graphql`
  query IndexQuery {
    allMarkdownRemark(
      filter: { fields: { sourceInstanceName: { eq: "markdown-pages" } } }
      sort: { order: DESC, fields: frontmatter___posted_at }
      limit: 10
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            developmentpost
            thumbnail
            posted_at(fromNow: true)
            slug
            category
          }
        }
      }
    }
  }
`
