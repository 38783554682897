import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import FeaturedThumbnailImage from "../components/FeaturedThumbnailImage"

const SpreadItem = styled.div`
  margin-bottom: 0;
  max-width: none;
  padding: 0;
`

const Teaser = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  @media (min-width: 768px) {
    flex-direction: column;
  }
  @media (max-width: 768px) {
    align-items: flex-start;
  }
`

const TeaserPicture = styled.div`
  display: grid;
  grid-template-areas: "imageArea";
  grid-area: picture;
  @media (min-width: 768px) {
    position: relative;
    flex: 0 0 auto;
  }
  @media (max-width: 768px) {
    width: 50%;
  }
`

const TeaserBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  z-index: 1;
  @media (min-width: 768px) {
    flex: 1 0 auto;
    padding-top: 16px;
  }
  @media (max-width: 768px) {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 0 0 1rem;
  }
`

const TeaserHeadline = styled(Link)`
  margin-bottom: 0.5rem;
  font-size: 16px;
  line-height: 1.35;
  width: 100%;
  color: black;
  @media (max-width: 768px) {
    flex: 0 0 100%;
  }
`

const TeaserKicker = styled(Link)`
  margin-bottom: 0.75rem;
  font-size: 11.3px;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  color: #ff4b2e;
  text-decoration: none;
  @media (max-width: 768px) {
    flex: 0 0 100%;
  }
`

const TeaserTimestamp = styled.div`
  display: block;
  margin: 0;
  font-size: 10.24px;
  flex: 0 0 auto;
  max-width: 100%;

  @media (min-width: 768px) {
    flex: 1 0 auto;
  }
`

const FeaturedPostCard = ({ slug, image, kicker, headline, timestamp }) => (
  <SpreadItem>
    <Teaser>
      <TeaserPicture>
        <FeaturedThumbnailImage src={image} />
      </TeaserPicture>
      <TeaserBody>
        <TeaserKicker to={`/blog/${slug}`}>{kicker}</TeaserKicker>
        <TeaserHeadline to={`/blog/${slug}`}>{headline}</TeaserHeadline>
        <TeaserTimestamp>{timestamp}</TeaserTimestamp>
      </TeaserBody>
    </Teaser>
  </SpreadItem>
)

export default FeaturedPostCard
