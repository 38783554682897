import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql, Link } from "gatsby"

import IndexHeaderImage from "../components/IndexHeaderImage"

const SpreadItems = styled.div`
  display: grid;
  grid-area: items;
  margin: 0 0 3rem 0;
  padding: 0;
`

const Teaser = styled.div`
  display: grid;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
  grid-template-areas: ". body .";
  grid-template-rows: [picture-start] 1fr [picture-end];
  grid-template-columns: [picture-start] 2fr minmax(min-content, 1fr) 0 [picture-end];
  position: relative;
  width: 100%;
`

const TeaserPicture = styled.div`
  display: grid;
  grid-area: picture;
  grid-template-areas: "imageArea";
  position: relative;
  overflow: hidden;
  max-height: 500px;
`

const TeaserBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-area: body;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
  @media (max-width: 768px) {
    background-color: unset;
    /* color: black !important; */
  }
  align-self: center;
  padding: 1.5rem;
  margin-right: 1.5rem;
  /* @media (max-width: 768px) {
    display: none;
  } */
  @media (max-width: 980px) {
    font-size: 1.6em;
    font-weight: 300;
    letter-spacing: 0.01em;
  }
`

const TeaserHeadline = styled(Link)`
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
  }
  @media (min-width: 768px) {
    color: #fff;
  }
  @media (max-width: 768px) {
    color: black;
  }
  @media (min-width: 48rem) {
    text-shadow: 2px 2px 16px #666;
  }
  @media (min-width: 48rem) {
    font-size: 1.6em;
  }
  text-align: left;
  text-decoration: none;
  line-height: 1.35;
  &:hover {
    background-color: unset !important;
    @media (min-width: 768px) {
      color: #fff;
    }
    @media (max-width: 768px) {
      color: black;
    }
  }
  margin-bottom: 0.5em;
`

const TeaserKicker = styled.div`
  text-transform: uppercase;
  letter-spacing: 0.12em;
  color: #ff4b2e;
  margin-bottom: 0.5rem;
  font-size: 12.8px;
  font-weight: bold;
`

const TeaserTimestamp = styled.div`
  font-size: small;
  color: #fff;
  @media (max-width: 768px) {
    color: black;
  }
`

const renderPost = (post, image, kicker, headline, timestamp) => (
  <SpreadItems>
    <Teaser>
      <TeaserPicture>
        <IndexHeaderImage src={image || post.node.frontmatter.thumbnail} />
      </TeaserPicture>
      <TeaserBody>
        <TeaserKicker>{kicker || post.node.frontmatter.category}</TeaserKicker>
        <TeaserHeadline to={`/blog/${post.node.frontmatter.slug}`}>
          {headline || post.node.frontmatter.title}
        </TeaserHeadline>
        <TeaserTimestamp>
          {timestamp || post.node.frontmatter.posted_at}
        </TeaserTimestamp>
      </TeaserBody>
    </Teaser>
  </SpreadItems>
)

const TeaserPost = ({ slug, image, kicker, headline, timestamp }) => (
  <StaticQuery
    query={graphql`
      query TeaserPostQuery {
        posts: allMarkdownRemark(
          filter: { fields: { sourceInstanceName: { eq: "markdown-pages" } } }
        ) {
          edges {
            node {
              frontmatter {
                title
                developmentpost
                thumbnail
                slug
                category
                posted_at(fromNow: true)
              }
            }
          }
        }
      }
    `}
    render={({ posts }) =>
      renderPost(
        posts.edges.find(post => post.node.frontmatter.slug === slug),
        image,
        kicker,
        headline,
        timestamp
      )
    }
  />
)

export default TeaserPost
