import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

import FeaturedPostCard from "../components/FeaturedPostCard"

const SpreadItems = styled.div`
  display: grid;
  padding: 0 0.5rem;
  margin-bottom: 3.5rem;
  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1.5rem;
    grid-row-gap: 2.25rem;
    padding: 0;
  }
`

const getSlugOverride = (slugs, slug, attr) =>
  slugs.find(s => s.slug === slug)[attr]

const FeaturedPosts = ({ slugs }) => (
  <StaticQuery
    query={graphql`
      query FeaturedPostsQuery {
        posts: allMarkdownRemark(
          filter: { fields: { sourceInstanceName: { eq: "markdown-pages" } } }
        ) {
          edges {
            node {
              frontmatter {
                title
                developmentpost
                thumbnail
                slug
                category
                posted_at(fromNow: true)
              }
            }
          }
        }
      }
    `}
    render={({ posts }) => (
      <SpreadItems>
        {posts.edges
          .filter(post =>
            slugs.map(s => s.slug).includes(post.node.frontmatter.slug)
          )
          .map(post => (
            <FeaturedPostCard
              slug={post.node.frontmatter.slug}
              image={
                getSlugOverride(slugs, post.node.frontmatter.slug, "image") ||
                post.node.frontmatter.thumbnail
              }
              kicker={
                getSlugOverride(slugs, post.node.frontmatter.slug, "kicker") ||
                post.node.frontmatter.category
              }
              headline={
                getSlugOverride(
                  slugs,
                  post.node.frontmatter.slug,
                  "headline"
                ) || post.node.frontmatter.title
              }
              timestamp={
                getSlugOverride(
                  slugs,
                  post.node.frontmatter.slug,
                  "timestamp"
                ) || post.node.frontmatter.posted_at
              }
            />
          ))}
      </SpreadItems>
    )}
  />
)

export default FeaturedPosts
