import React from "react"
import styled from "styled-components"

import LatestPost from "../components/LatestPost"

const Title = styled.h4`
  font-weight: 400;
  font-size: x-large;
  letter-spacing: 0.15em;
  text-transform: uppercase;
`

const LatestPosts = ({ posts }) => (
  <>
    <Title>The Latest</Title>
    {posts.map(post => (
      <LatestPost
        key={post.node.frontmatter.slug}
        slug={post.node.frontmatter.slug}
        image={post.node.frontmatter.thumbnail}
        kicker={post.node.frontmatter.category}
        headline={post.node.frontmatter.title}
        description={post.node.frontmatter.description}
        timestamp={post.node.frontmatter.posted_at}
      />
    ))}
  </>
)

export default LatestPosts
