import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import FeaturedThumbnailImage from "../components/FeaturedThumbnailImage"

const SpreadItem = styled.div`
  margin-bottom: 1.5rem;
`

const Teaser = styled.div`
  display: flex;
  position: relative;
  align-items: flex-start;
  box-sizing: border-box;
  text-decoration: none;
  width: 100%;
`

const TeaserPicture = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  @media (min-width: 768px) {
    order: 2;
    min-width: 0;
  }
  display: grid;
  grid-template-areas: "imageArea";
  position: relative;
  overflow: hidden;
  height: 100%;
`

const TeaserBody = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex: 1 0 0px;
    max-width: none;
    min-width: 0;
    margin-right: 1.5rem;
    padding: 0 1rem 0 0;
    border-top: 1px solid #ddd;
  }
  @media (max-width: 768px) {
    flex: 0 0 50%;
    flex-wrap: wrap;
    max-width: 50%;
    padding: 0 0 0 1rem;
  }
`

const TeaserKicker = styled(Link)`
  margin-top: 0.5em;
  margin-bottom: 0.75em;
  font-size: 11.23px;
  width: 100%;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.02em;
  color: #ff4b2e;
  @media (max-width: 768px) {
    flex: 0 0 100%;
    order: 2;
  }
`

const TeaserHeadline = styled(Link)`
  line-height: 1.35;
  font-size: xx-large;
  color: black;
  width: 100%;
  @media (max-width: 768px) {
    order: 3;
  }
`

const TeaserTimestamp = styled.div`
  width: 100%;
  font-size: small;
  @media (max-width: 768px) {
    order: 4;
  }
`

const TeaserDescription = styled.div`
  width: 100%;
  margin-top: 2.5rem;
  font-size: small;
  color: black;
  @media (max-width: 768px) {
    order: 5;
  }
`

const TrucatedDescription = ({ description, slug }) => (
  <TeaserDescription>
    {description.substring(0, 80)}...
    <Link to={`/blog/${slug}`}>Read More</Link>
  </TeaserDescription>
)

const LatestPost = ({
  slug,
  image,
  kicker,
  headline,
  description,
  timestamp,
}) => (
  <SpreadItem>
    <Teaser>
      <TeaserPicture>
        <FeaturedThumbnailImage src={image} />
      </TeaserPicture>
      <TeaserBody>
        <TeaserKicker to={`/blog/${slug}`}>{kicker}</TeaserKicker>
        <TeaserHeadline to={`/blog/${slug}`}>{headline}</TeaserHeadline>
        <TeaserTimestamp>{timestamp}</TeaserTimestamp>
        <TrucatedDescription description={description} slug={slug} />
      </TeaserBody>
    </Teaser>
  </SpreadItem>
)

export default LatestPost
